<template>
    <v-overlay opacity="0.2" :value="overlay"> 
        <v-alert type="success">
            {{ text }}
            <label v-if="redirectTo != null">กำลังกลับไปหน้าหลักภายใน {{ timer }} วินาที</label>
        </v-alert>
    </v-overlay>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: ''
        },
        redirectTo: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        overlay: false,
        timer: 3
    }),
    methods: {
        show(){
            this.overlay = true;
            setTimeout(() => {
                this.overlay = false;
            }, 5000);
        },
        hide(){
            this.overlay = false;
        }
    },
    watch: {
        overlay(){
            if(this.redirectTo != null && true == this.overlay){
                let itv = setInterval(() => {
                    this.timer -= 1;
                    if(this.timer < 1){
                        clearInterval(itv);
                        this.$router.push(this.redirectTo);
                    }
                }, 1000);
            }
        }
    }
};
</script>