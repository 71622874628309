<template>
    <v-app style="background-color: #191B20;">
        <v-container class="my-3 px-5">
            <v-row>
                <v-col>
                    <v-img
                    class="my-4"
                    lazy-src="https://picsum.photos/id/11/10/6"
                    max-height="64"
                    max-width="100"
                    src="@/assets/images/logo1.svg"
                    ></v-img>
                </v-col>
            </v-row>
            <v-row class="mt-12">
                <v-col>
                    <p class="text-h4 mb-0 yellow--text text--darken-3">ยืนยันตัวตนผู้ใช้งาน</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-n5 my-0 white--text">
                    <p class="text-subtitle-1">โปรดตรวจสอบข้อมูลให้ถูกต้องก่อนกดยืนยัน</p>
                </v-col>
                
            </v-row>
            <v-row>
                <v-col sm="12" md="4">
                <v-form ref="verifyForm" v-model="isValid">
                    <v-text-field
                        dark
                        color="yellow darken-3"
                        label="ชื่อผู้ใช้งาน"
                        outlined
                        dense
                        disabled
                        v-model="username"
                    ></v-text-field>
                    <v-text-field
                        dark
                        color="yellow darken-3"
                        type="password"
                        label="ตั้งรหัสผ่าน"
                        outlined
                        dense
                        counter="20"
                        :rules="rulesPassword"
                        v-model="password"
                    ></v-text-field>
                    <v-text-field
                        dark
                        color="yellow darken-3"
                        type="password"
                        label="ยืนยันรหัสผ่าน"
                        outlined
                        dense
                        counter="20"
                        :rules="[v => v == password || 'รหัสผ่านไม่ตรงกัน']"
                        v-model="confirmPassword"
                    ></v-text-field>
                    <v-row>
                        <v-col cols="12">
                            <v-btn color="yellow darken-3" style="width: 100%" @click="submit">ยืนยันตัวตนผู้ใช้งาน</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                </v-col>
            </v-row>
        </v-container>
        <alert-error :text="errorMessage" ref="alertError" />
        <alert-success :text="`${successMessage} ${username} `" :redirectTo="{ name: 'Login' }" ref="alertSuccess" />
    </v-app>
</template>

<script>
import axios from 'axios';
import AlertError from '../components/AlertError.vue';
import AlertSuccess from '../components/AlertSuccess.vue';

export default {
    data: () => ({
        token: '',
        username: '',
        password: '',
        confirmPassword: '',
        rulesPassword: [
            value => !!value || 'โปรดใส่รหัสผ่าน',
            value => value.length >= 8 || 'ความยาวของรหัสผ่านอย่างน้อย 8 ตัวอักษร',
            value => value.length <= 20 || 'รหัสผ่านเกิน 20 ตัวอักษร'
        ],
        isValid: false,
        errorMessage: '',
        successMessage: 'ยืนยันตัวตนของผู้ใช้สำเร็จแล้ว สามารถเข้าสู่ระบบด้วยอีเมล '
    }),
    methods: {
        async submit(){
            this.$refs.verifyForm.validate();
            const validPassword = this.password == this.confirmPassword;
            if(this.isValid && validPassword){
                let loader = this.$loading.show();
                try{
                    await axios.post(process.env.VUE_APP_BASE_URL + '/users/verify?token=' + this.token, {
                        username: this.username,
                        password: this.password
                    });
                    localStorage.clear();
                    this.$refs.alertSuccess.show();
                }catch(error){
                    this.errorMessage = error.response?.data[0]?.msg || error.response?.data?.fail || error.message;
                    this.$refs.alertError.show();
                }finally{
                    loader.hide();
                }
            }
        }
    },
    mounted(){
        this.token = this.$route.query?.token || '';
        this.username = this.$route.query?.username || '';
    },
    watch: {
    },
    components: {
        AlertError,
        AlertSuccess
    }
};
</script>

<style scoped>
    .w-400px{
        /* width: 400px; */
    }
</style>